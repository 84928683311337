var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"XLnJ5wJA5VLpPE4j6yJU1"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/static/marketplace";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

import config from './src/client/config';

const nextConfig = getConfig().publicRuntimeConfig;

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

process.env.NODE_ENV == 'production' && Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://f157037071d245c293f98d0fac638349@o142289.ingest.sentry.io/6726986',
  // Now we get 0.1 of the transactions trough, we lower this on production to not exceed our transaction quote
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  initialScope: {
    tags: {
      clientSide: true,
      serverSide: false,
    },
  },
  environment: process.env.NODE_ENV == 'production' ? nextConfig.tier : 'development',
  release:
    process.env.NODE_ENV == 'production' ? config.githash() : 'development',
});

Sentry.configureScope((scope) => {
  scope.setTag('app', 'marketplace');
});
